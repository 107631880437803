<template>
  <div id="h-about">
    <div class="about-wrapper">
      <div class="text">
        <div class="title-wrapper">
          <h6 class="menu-title">OUR STORY</h6>
          <h3 class="sub-title">
            <span style="color: #f2994a">30</span> years of crafting
            extraordinary experiences.
          </h3>
        </div>
        <p class="story-desc">
          Established in 1991, Pan Island Holidays has been a trusted partner in
          business tourism for over three decades in Penang. With our extensive
          experience and deep understanding of the region, we specialise in
          curating unforgettable experiences for business meetings and incentive
          trips. Our personalised approach ensures that every aspect of your
          event, from planning to execution, is meticulously tailored to your
          specific requirements.<br /><br />
          Whether you're organising a corporate retreat or an incentive trip,
          our dedicated team is committed to delivering excellence and exceeding
          your expectations. Let us guide you through the richness and beauty of
          Penang and Langkawi, backed by 30 years of expertise and dedication to
          exceptional service in business tourism.
        </p>
      </div>
      <div class="divider">
        <div class="dotted-line"></div>
        <img src="@/assets/img/footnote.png" alt="" />
      </div>
      <div class="about-data">
        <div class="number-wrapper">
          <h4 class="number-title">30</h4>
          <p class="number-sub-title">years</p>
          <p class="number-desc">of experience in Penang</p>
        </div>
        <div class="number-wrapper middle-number-wrapper">
          <h4 class="number-title">10000+</h4>
          <p class="number-sub-title">trips</p>
          <p class="number-desc">and happy travellers!</p>
        </div>
        <div class="number-wrapper">
          <h4 class="number-title">40+</h4>
          <p class="number-sub-title">countries</p>
          <p class="number-desc">Travellers from 40+ countries</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script></script>
<style lang="scss" scoped>
@import "@/assets/css/all.scss";
@import "@/assets/css/reset.css";

#h-about {
  background-color: #fff8f2;
  padding: 48px 15px;
}

.sub-title {
  padding: 8px 0;
}

.text p {
  color: $gray2;
}

.divider {
  display: flex;
  align-items: center;
  padding: 32px 0;
  .dotted-line {
    height: 1px;
    width: 100%;
    border-top: 1px dotted #bdbdbd;
  }

  img {
    padding-left: 8px;
    width: 24px;
  }
}

.about-data {
  display: flex;
  flex-direction: column;
}

.middle-number-wrapper {
  border-top: 1px dotted #bdbdbd;
  border-bottom: 1px dotted #bdbdbd;
}

.number-wrapper {
  text-align: center;
  padding-top: 32px;
  //   width: 33%;
  padding: 72px 0 64px;
}

.number-title {
  font-family: "Halant";
  font-size: 96px;
  color: $gray1;
  line-height: 0.75;
  font-weight: 400;
}

.number-sub-title {
  font-family: "Halant";
  font-size: 32px;
  color: $gray3;
  font-weight: 400;
}

.number-desc {
  font-size: 16px;
  color: $gray2;
  letter-spacing: 5%;
  padding-top: 8px;
}

@media only screen and (min-width: 768px) {
  #h-about {
    padding: 72px 15px;
  }
  .text {
    display: flex;
    align-items: top;
    margin: 0 auto;
  }
  .title-wrapper {
    width: 50%;
  }
  .story-desc {
    width: 50%;
  }
  .about-wrapper {
    max-width: 1100px;
    margin: 0 auto;
  }
  .about-data {
    flex-direction: row;
  }
  .middle-number-wrapper {
    border: 0px;
    border-right: 1px dotted #bdbdbd;
    border-left: 1px dotted #bdbdbd;
    border-right: 1px dotted #bdbdbd;
  }
  .number-wrapper {
    width: 33%;
  }
}
</style>
