import { createApp } from 'vue'
import App from './App.vue'
// import VueMeta from 'vue-meta'

createApp(App).mount('#app')

// App.use(VueMeta, {
//     // optional pluginOptions
//     refreshOnceOnNavigation: true
//   })
