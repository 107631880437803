<template>
  <div id="h-hero">
    <div class="h-hero-wrapper">
      <h1>Your Corporate Journey in Penang, Guided by Locals</h1>
      <p>
        With three decades of experience, we've curated exceptional journeys in
        Penang. Our dedicated team ensures an extraordinary experience for our
        clients.
      </p>
      <div class="primary-button" @click="buttonClicked('h-contact')">
        Start planning<img src="@/assets/img/primary-btn-arrow.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeroSection",
  data() {},
  methods: {
    buttonClicked(routeId) {
      this.$emit("transferToHomepage", routeId);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/all.scss";
@import "@/assets/css/reset.css";

#h-hero {
  height: 600px;
  padding: 15px 15px;
  background: url("@/assets/img/homepage-bg.png") center/cover no-repeat;
  padding-top: 100px;
  h1 {
    font-family: "Halant";
    font-size: 48px;
    color: white;
    padding-bottom: 16px;
    line-height: 60px;
  }
  p {
    font-family: "Nunito";
    font-size: 16px;
    color: white;
    line-height: 20px;
    font-weight: 300;
    padding-bottom: 16px;
  }
}
@media only screen and (min-width: 768px) {
  #h-hero {
    text-align: center;
  }
  .h-hero-wrapper {
    margin: auto;
    max-width: 1000px;
  }
  h1 {
    font-size: 64px !important;
    padding-top: 125px;
    line-height: 75px !important;
  }
  p {
    max-width: 700px;
    margin: auto;
  }
  .primary-button {
    margin: auto;
  }
}
</style>
