<template>
  <div id="footer">
    <div class="footer-wrapper">
      <div class="logo-wrapper">
        <div class="logo">
          <img class="logo-img" src="@/assets/img/just-logo.svg" alt="" />
          <h6 class="company-name">Pan Island Holidays Sdn. Bhd.</h6>
          <p class="company-no">Company No. 1012980901820120</p>
        </div>
        <div class="detail-wrapper address-wrapper">
          <h6 class="footer-heading">Address</h6>
          <p class="detail">
            12, Jalan Pantai Miami, 11100 Batu Ferringhi, Penang, Malaysia
          </p>
        </div>
      </div>
      <div class="footer-dets">
        <div class="detail-wrapper">
          <h6 class="footer-heading">Email us</h6>
          <a href="mailto:hello@pan-islandholidays.com" class="detail"
            >hello@pan-islandholidays.com</a
          >
        </div>
        <div class="detail-wrapper">
          <h6 class="footer-heading">Call us</h6>
          <a href="tel:+60102117724" class="detail">+6010-211-7724</a>
        </div>
        <div class="detail-wrapper">
          <h6 class="footer-heading">WhatsApp</h6>
          <a href="tel:+60102117724" class="detail">+6010-211-7724</a>
        </div>
      </div>
    </div>
    <div class="cpright-wrapper">
      <div
        style="
          color: rgba(255, 255, 255, 0.5);
          font-size: 12px;
          font-family: Nunito;
          font-weight: 500;
          text-transform: uppercase;
          word-wrap: break-word;
        "
      >
        © 2024 Pan Island Holidays Sdn. Bhd. ALL RIGHTS RESERVED
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterView",
};
</script>
<style scoped lang="scss">
@import "@/assets/css/all.scss";
@import "@/assets/css/reset.css";
#footer {
  background-color: #06131d;
  color: white;
}
.footer-wrapper {
  max-width: 2048px;
  margin: 0 auto;
  border-top: 2px solid rgba(255, 255, 255, 0.15);
  padding: 32px 15px 32px 15px;
}
.logo-img {
  width: 50px;
}
.logo {
  padding-bottom: 32px;
}
.company-name {
  font-size: 20px;
  font-weight: 700;
  padding-top: 16px;
}
.detail-wrapper {
  padding-bottom: 32px;
}
.company-no {
  color: $gray5;
  font-size: 12px;
}
.footer-heading {
  font-size: 16px;
  font-weight: 600;
  padding-bottom: 4px;
}

.detail {
  font-size: 14px;
  color: $gray5;
  text-decoration: none;
}

.cpright-wrapper {
  background-color: rgba(255, 255, 255, 0.1);
  padding: 8px 15px;
  text-align: center;
}

@media only screen and (min-width: 768px) {
  .footer-wrapper {
    width: calc(100vw - 160px);
    display: flex;
    justify-content: space-between;
  }
  .logo-wrapper {
    max-width: 300px;
    margin-right: 16px;
  }
  .footer-dets {
    display: flex;
    margin-top: 96px;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .detail-wrapper {
    padding-bottom: 0px !important;
    padding-right: 64px;
  }
  .address-wrapper {
    padding-right: 0;
  }
}
</style>
