<template>
  <Header @scrollToElement="tapped" />
  <HomepageView @scrollToElement="tapped" />
  <Footer />
</template>

<script>
import Header from "./components/Header.vue";
import HomepageView from "./views/HomepageView.vue";
import Footer from "./components/Footer.vue";

export default {
  name: "App",
  components: {
    HomepageView,
    Header,
    Footer,
  },
  methods: {
    tapped(route) {
      // let el = document.getElementById(route);
      // event.preventDefault();
      // el.scrollIntoView({ behavior: "smooth" });
      let element = document.getElementById(route); // Your target element
      let headerOffset = 45;
      let elementPosition = element.getBoundingClientRect().top;
      let offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style>
@import "../src/assets/css/reset.css";
@import "../src/assets/css/all.scss";

#app {
  max-width: 2160px;
  font-family: "Nunito";
  margin: 0 auto;
}
</style>
