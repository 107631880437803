<template>
  <div>
    <div class="text-wall">
      <div class="text-wrapper first">
        <div class="text d-flex flex-nowrap">
          <span class="display-text">Let’s start planning</span>
        </div>
        <div class="text d-flex flex-nowrap">
          <span class="display-text">Let’s start planning</span>
        </div>
        <div class="text d-flex flex-nowrap">
          <span class="display-text">Let’s start planning</span>
        </div>
        <div class="text d-flex flex-nowrap">
          <span class="display-text">Let’s start planning</span>
        </div>
      </div>

      <div class="text-wrapper second">
        <div class="text d-flex flex-nowrap">
          <span class="display-text">Let’s start planning</span>
        </div>
        <div class="text d-flex flex-nowrap">
          <span class="display-text">Let’s start planning</span>
        </div>
        <div class="text d-flex flex-nowrap">
          <span class="display-text">Let’s start planning</span>
        </div>
        <div class="text d-flex flex-nowrap">
          <span class="display-text">Let’s start planning</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script></script>
<style scoped lang="scss">
@import "@/assets/css/all.scss";
@import "@/assets/css/reset.css";

.display-text {
  font-size: 64px;
  color: $gray1;
  font-family: "Halant";
  padding-right: 46px;
  font-weight: 100;
  text-wrap: nowrap;
}

@keyframes scroll {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(100%);
  }
}

@keyframes scroll2 {
  from {
    transform: translateX(-200%);
  }
  to {
    transform: translateX(0%);
  }
}

.text-wall {
  display: flex;
  width: 100% !important;
  overflow: hidden;
  .text-wrapper {
    display: flex;
    animation: scroll 100s linear infinite;
    cursor: pointer;
    &:nth-child(2) {
      animation: scroll2 100s linear infinite;
      animation-delay: -50s;
    }
  }
}
.text-wall:hover {
  .text-wrapper,
  .text-wrapper:nth-child(2) {
    animation-play-state: paused;
  }
}

@media only screen and (min-width: 768px) {
  .display-text {
    font-size: 96px;
    padding-right: 86px;
  }
}
</style>
