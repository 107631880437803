<template>
  <div id="h-solulu">
    <div class="solulu-wrapper">
      <div class="solulu-left">
        <div class="text-wrapper">
          <h6 class="menu-title">services</h6>
          <h2 class="solulu-sub-title">Start Incredible Experiences Here</h2>
          <p>
            We specialise in crafting bespoke experiences for incentive tours,
            corporate retreats, business events, and meetings. Whether you're
            looking for ideas or ready to start planning your next unforgettable
            event, we're here to help.
          </p>
          <div
            class="tertiary-button"
            style="padding-top: 8px"
            @click="buttonClicked('h-contact')"
          >
            Start planning
            <img src="@/assets/img/tertiary-btn-down.png" alt="" />
          </div>
        </div>
        <div class="solulu-package">
          <img src="@/assets/img/sol-1.jpg" alt="" />
          <h3 class="solu-title">Incentive trips</h3>
          <p class="desc">
            Motivate your team with unforgettable trips! Explore exciting
            destinations, engage in thrilling activities, and celebrate success
            together.
          </p>
        </div>
        <div class="solulu-package" style="padding: 48px 0">
          <img src="@/assets/img/sol-2.jpg" alt="" />
          <h3 class="solu-title">Corporate meetings</h3>
          <p class="desc">
            Boost productivity with seamless meetings! Plan and execute
            important discussions efficiently, fostering collaboration and
            achieving business objectives.
          </p>
        </div>
      </div>
      <div class="solulu-right">
        <div class="solulu-package">
          <img src="@/assets/img/sol-3.jpg" alt="" />
          <h3 class="solu-title">Corporate retreats</h3>
          <p class="desc">
            Strengthen team bonds with relaxing retreats! Enjoy peaceful
            getaways, participate in engaging team-building exercises, and
            recharge your team's energy.
          </p>
        </div>
        <div class="solulu-package" style="padding-top: 48px">
          <img src="@/assets/img/sol-4.jpg" alt="" />
          <h3 class="solu-title">Business events</h3>
          <p class="desc">
            Impress clients and partners with memorable events! Host successful
            company events, corporate gatherings, or networking functions,
            showcasing your brand's excellence.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SolutionSection",
  data() {},
  methods: {
    buttonClicked(routeId) {
      this.$emit("transferToHomepage", routeId);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/all.scss";
@import "@/assets/css/reset.css";
#h-solulu {
  padding: 15px 15px;
}

p {
  font-size: 16px;
  color: #717171;
}
.solulu-wrapper {
  padding-top: 48px;
}
.solulu-sub-title {
  font-size: 40px;
  padding: 8px 0;
  font-family: "Halant";
  color: $gray1;
}

.text-wrapper {
  padding-bottom: 48px;
}

.solu-title {
  font-family: "Halant";
  font-size: 24px;
  color: $gray2;
  padding: 24px 0 8px 0;
}

.solulu-package {
  img {
    width: 100%;
    height: auto;
  }
}

@media only screen and (min-width: 768px) {
  .solulu-wrapper {
    display: flex;
    max-width: 1100px;
    justify-content: space-between;
    margin: 0 auto;
    padding-top: 72px;
  }
  .solulu-left {
    width: 40%;
  }
  .solulu-right {
    width: 40%;
  }
}
</style>
