<template>
  <div class="navbar" :class="{ transparent: isTransparent }">
    <div class="logo" @click="headerClicked('h-hero')">
      <img :src="this.logoUrl" alt="Logo" />
    </div>
    <div class="menu" :class="{ open: isOpen }">
      <ul>
        <li @click="headerClicked('h-solulu')">Services</li>
        <li @click="headerClicked('h-about')">Our story</li>
        <li @click="headerClicked('h-why')">Why Us</li>
        <li @click="headerClicked('h-contact')">Contact Us</li>
      </ul>
    </div>
    <div class="primary-button" @click="headerClicked('h-contact')">
      Start planning
    </div>
    <div class="menu-icon" @click="toggleMenu">
      <div class="line"></div>
      <div class="line"></div>
      <div class="line"></div>
    </div>
  </div>
  <div class="overlay" :class="{ open: isOpen }" @click="toggleMenu">
    <div class="overlay-wrapper">
      <ul>
        <li @click="headerClicked('h-solulu')">Services</li>
        <li @click="headerClicked('h-about')">Our story</li>
        <li @click="headerClicked('h-why')">Why Us</li>
        <li @click="headerClicked('h-contact')">Contact Us</li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  name: "HeaderView",
  data() {
    return {
      isOpen: false,
      isTransparent: true,
      logoUrl: require("../assets/img/white-logo.svg"),
    };
  },
  methods: {
    toggleMenu() {
      this.isOpen = !this.isOpen;
    },
    checkScreenWidth() {
      if (window.innerWidth < 768) {
        this.isOpen = false;
      }
    },
    handleScroll() {
      if (window.pageYOffset > 1) {
        this.isTransparent = false;
        this.logoUrl = require("../assets/img/logo.svg");
      } else {
        this.isTransparent = true;
        this.logoUrl = require("../assets/img/white-logo.svg");
      }
    },
    headerClicked(routeId) {
      this.$emit("scrollToElement", routeId);
    },
  },
  mounted() {
    this.checkScreenWidth();
    // Listen for window resize events
    window.addEventListener("resize", this.checkScreenWidth);
    window.addEventListener("scroll", this.handleScroll);
  },
};
</script>
<style scoped lang="scss">
.transparent {
  background-color: rgba(0, 0, 0, 0) !important;
  transition: 0.25s;
  a {
    color: #ffffff !important;
  }
}
.primary-button {
  font-size: 16px !important;
}

.logo {
  img {
    max-width: 200px;
    cursor: pointer;
  }
}
.navbar {
  background-color: #06131d;
  transition: 0.25s;
  color: #ffffff;
  padding: 12px 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;

  top: 0;
  width: 100%;
  max-width: 2160px;
  z-index: 99;
  box-sizing: border-box;
}

.menu {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.menu ul {
  list-style: none;
  padding: 0;
  display: flex;
}

.menu ul li {
  margin: 0 16px;
  padding: 4px 0;
  cursor: pointer;
  border-bottom: 2px solid rgba(255, 255, 255, 0);
  transition: 0.5s;
  &:hover {
    border-bottom: 2px solid white;
    transition: 0.5s;
  }
}

.menu ul li a {
  color: #ffffff;
  text-decoration: none;
}

.logo {
  position: relative; /* Add position relative to keep the logo in place */
}

.phone {
  font-size: 1rem;
}

.menu-icon {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.line {
  width: 25px;
  height: 3px;
  background-color: #fff;
  margin: 3px 0;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.85);
  z-index: 100;
  display: none;
}

.open {
  display: block;
}

/* Media query for mobile devices */
@media only screen and (max-width: 768px) {
  .menu {
    display: none;
  }

  .primary-button {
    display: none;
  }

  .menu-icon {
    display: flex;
  }

  .menu.open ul {
    display: flex;
    flex-direction: column;
  }

  .menu.open ul li {
    margin: 15px 0;
  }
  .navbar {
    padding: 15px 15px;
  }
  .overlay ul li a {
    text-decoration: none;
    color: #4f4f4f;
    font-size: 32px;
  }
  .overlay ul li {
    padding-bottom: 32px;
  }
  .overlay-wrapper {
    padding: 64px 15px 0 15px;
  }
}
</style>
