<template>
  <div id="h-contact">
    <div class="contact-wrapper">
      <h6 class="menu-title">contact us</h6>
      <h2 class="sub-title" style="padding-top: 8px">
        Let's start planning
        <span style="color: #f2994a">something amazing</span> together!
      </h2>
      <form class="field-wrapper" @submit.prevent="sendEmail">
        <div class="field-100">
          <input
            type="text"
            class="field"
            placeholder="Name"
            maxlength="200"
            v-model="formData.name"
            required
          />
          <input
            type="text"
            class="field"
            placeholder="Company name"
            maxlength="200"
            v-model="formData.companyName"
          />
        </div>
        <div class="field-100">
          <input
            type="email"
            class="field"
            placeholder="Company email"
            maxlength="200"
            v-model="formData.email"
            required
          />
          <input
            type="tel"
            class="field"
            placeholder="Phone"
            maxlength="20"
            v-model="formData.phone"
          />
        </div>

        <select id="dropdown" v-model="formData.service">
          <option value="What service are you looking for?" disabled selected>
            What service are you looking for?
          </option>
          <option value="Incentive trip">Incentive trips</option>
          <option value="Corporate retreats">Corporate retreats</option>
          <option value="Coporate meetings">Coporate meetings</option>
          <option value="Business events">Business events</option>
        </select>

        <input
          type="text"
          class="field"
          placeholder="When will your event take place?"
          maxlength="100"
          v-model="formData.whenToVisit"
        />
        <input
          type="text"
          class="field"
          placeholder="How many of you are coming?"
          maxlength="100"
          v-model="formData.totalPax"
        />
        <input
          type="text"
          class="field"
          placeholder="Your message"
          maxlength="500"
          v-model="formData.message"
        />
        <div class="checkbox-wrapper">
          <label class="container"
            >I’m happy for you to contact me using the above details
            <input type="checkbox" required />
            <span class="checkmark"></span>
          </label>
          <!-- <input type="checkbox" name="consent" id="consent" /><label
            for="consent"
            >I’m happy for you to contact me using the above details</label
          > -->
        </div>
        <button
          class="primary-button loading-button"
          type="submit"
          :class="{ whiteBg: loading }"
          :disabled="disableBtn"
        >
          <span v-if="!loading">{{ buttonMessage }}</span>
          <span v-if="loading" class="spinner"></span>
        </button>
      </form>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      formData: {
        name: "",
        companyName: "",
        email: "",
        phone: "",
        service: "What service are you looking for?",
        whenToVisit: "",
        totalPax: "",
        message: "",
      },
      loading: false,
      disableBtn: false,
      buttonMessage: "Submit",
    };
  },
  methods: {
    sendEmail() {
      // Send form data to backend
      this.loading = true;
      this.disableBtn = true;
      fetch("https://pan-island-server-64089e93d498.herokuapp.com/send-email", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(this.formData),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to send email");
          }
          return response.json();
        })
        .then(() => {
          // Reset form data
          this.formData.name = "";
          this.formData.companyName = "";
          this.formData.email = "";
          this.formData.phone = "";
          this.formData.service = "What service are you looking for?";
          this.formData.whenToVisit = "";
          this.formData.totalPax = "";
          this.formData.message = "";
          // Show success message
          this.loading = false;
          this.disableBtn = false;
          this.buttonMessage = "Submit";

          // setTimeout(() => {
          //   this.buttonMessage = "Submit";
          // }, 5000); // Hide success message after 5 seconds
        })
        .catch(() => {
          // Show error message
          this.buttonMessage = "Unable to submit. Please try again.";
        });
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/css/all.scss";
@import "@/assets/css/reset.css";
.whiteBg {
  background-color: white !important;
}

#h-contact {
  background-color: #06131d;
  padding: 42px 15px;
}
.contact-wrapper {
  border-radius: 8px;
  margin: 0 auto;
}
input:focus,
select:focus {
  border: 0px;
  border-bottom: 1px solid $primary-color;
  outline: 0;
  transition: 0.5s;
}
.sub-title {
  color: white;
}
.field,
select {
  width: 100%;
  border: 0px;
  border-bottom: 1px solid $gray5;
  height: 36px;
  font-size: 16px;
  font-family: "Nunito";
  margin-bottom: 16px;
  padding: 0 8px;
  box-sizing: border-box;
  background-color: #06131d;
  color: white;
  &::placeholder {
    color: white;
    opacity: 0.8;
  }

  &::-ms-input-placeholder {
    /* Edge 12 -18 */
    color: white;
  }
}
.field-wrapper {
  padding-top: 24px;
}

.checkbox-wrapper {
  width: 100%;
  cursor: pointer;
  margin-top: 8px;
}

/* Customize the label (the container) */
.container {
  display: block;
  position: relative;
  padding-left: 24px;
  margin-bottom: 24px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #ffffff;
  transition: 0.5s;
  border-radius: 2px;
}
.primary-button {
  background-color: #06131d;
  font-family: "Nunito";
  width: 100%;
  justify-content: center;
  height: 48.5px;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: $gray6;
  transition: 0.5s;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: $primary-color;
  border: 0px;
  transition: 0.5s;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 5px;
  top: 2.5px;
  width: 5px;
  height: 7px;
  border: solid white;
  border-width: 0 1.75px 1.75px 0;

  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

input[type="checkbox"] {
  width: 16px;
  height: 16px;
  margin: 0 8px 0 0;
  background-color: $primary-color;
}

label {
  font-size: 14px;
  color: $gray3;
  line-height: 16px;
}
@media only screen and (min-width: 768px) {
  #h-contact {
    padding: 86px 15px !important;
  }
  .contact-wrapper {
    max-width: 1100px;
  }
  .field,
  select {
    margin: 12px 16px 12px 0;
  }
  .field-100 {
    display: flex;
    margin: 0 auto;
    width: 100%;
  }
  .field-wrapper {
    display: flex;
    flex-wrap: wrap;
    max-width: 600px;
  }
  .checkbox-wrapper {
    // margin: 8px 24px;
  }
  .checkbox-wrapper {
    display: flex;
    align-items: center !important;
    cursor: pointer;
  }
  .primary-button {
    margin-right: 16px;
  }
}

.loading-button {
  position: relative;
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
  border: 2px solid transparent;
  border-top-color: $primary-color;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  display: none;
}

@keyframes spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.loading-button:disabled .spinner {
  display: block;
}

.loading-button:disabled span {
  display: none;
}
</style>
