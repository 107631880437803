<template>
  <div>
    <HeroSection @transferToHomepage="btnClicked" />
    <SolutionSection @transferToHomepage="btnClicked" />
    <AboutSection />
    <WhySection />
    <TalkSection />
    <ContactSection />
  </div>
</template>
<script>
import HeroSection from "../components/Homepage/HeroSection.vue";
import SolutionSection from "../components/Homepage/SolutionSection.vue";
import AboutSection from "../components/Homepage/AboutSection.vue";
import WhySection from "../components/Homepage/WhySection.vue";
import TalkSection from "../components/Homepage/TalkSection.vue";
import ContactSection from "../components/Homepage/ContactSection.vue";

export default {
  name: "HomepageView",
  components: {
    HeroSection,
    SolutionSection,
    AboutSection,
    WhySection,
    TalkSection,
    ContactSection,
  },
  metaInfo: {
    title: "Pan Island Holidays",
    titleTemplate: "Your Corporate Journey in Penang, Guided by Local",
    htmlAttrs: {
      lang: "en",
      amp: true,
    },
  },
  methods: {
    btnClicked(routeId) {
      this.$emit("scrollToElement", routeId);
    },
  },
};
</script>

<style lang="scss" scoped>
h1 {
  font-size: 900px;
}
</style>
