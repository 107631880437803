<template>
  <div id="h-why">
    <div class="why-wrapper">
      <h2 class="sub-title" style="padding-bottom: 24px">
        Why travel with <br />
        Pan Islands?
      </h2>
      <div class="top-divider"></div>
      <div class="bottom-divider"></div>
      <div class="reasons-wrapper">
        <div class="reasons-left">
          <div class="reason">
            <img src="@/assets/img/map.svg" alt="" />
            <h6 class="reason-title">Personalised Service</h6>
            <p>
              From personalised travel itineraries to bespoke event planning, we
              cater to your unique preferences and desires. Whether you're
              seeking an unforgettable journey or a memorable event, our expert
              team ensures every detail is crafted to perfection.
            </p>
          </div>
          <div class="reason">
            <img src="@/assets/img/point.svg" alt="" />
            <h6 class="reason-title">Local Connections</h6>
            <p>
              Benefit from our network of local partners and guides, providing
              you with authentic experiences and access to hidden gems.
            </p>
          </div>
        </div>
        <div class="reasons-right">
          <div class="reason">
            <img src="@/assets/img/earth.svg" alt="" />
            <h6 class="reason-title">Expertise</h6>
            <p>
              Rely on our team to deliver tailored travel arrangements. Benefit
              from our years of expertise in crafting memorable experiences
              tailored to your needs.
            </p>
          </div>
          <div class="reason">
            <img src="@/assets/img/mic.svg" alt="" />
            <h6 class="reason-title">Customer Support</h6>
            <p>
              Our dedicated team is here to provide personalised assistance,
              ensuring that your queries and concerns are addressed with care
              and efficiency
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script></script>

<style lang="scss" scoped>
@import "@/assets/css/all.scss";
@import "@/assets/css/reset.css";
#h-why {
  padding: 15px 15px;
}
p {
  font-size: 16px;
  color: #717171;
}
.why-wrapper {
  padding-top: 48px;
}

.top-divider {
  border-top: 1px solid $gray5;
  height: 20px;
  width: 290px;
  padding-bottom: 5px;
}
.bottom-divider {
  border-top: 1px solid $gray6;
  height: 20px;
  width: 160px;
}

.reasons-wrapper {
  padding-top: 32px;
}

.reason-title {
  font-size: 20px;
  font-weight: 600;
  color: $gray1;
  padding: 12px 0;
}

.reason {
  padding-bottom: 32px;
}

@media only screen and (min-width: 768px) {
  .why-wrapper {
    padding: 120px 0;
  }

  .top-divider,
  .bottom-divider {
    margin: 0 auto;
  }

  .sub-title {
    text-align: center;
  }
  .reasons-wrapper {
    max-width: 700px;
    margin: 0 auto;
    display: flex;
  }
  .reasons-left,
  .reasons-right {
    display: flex;
    flex-direction: column;
    padding: 0 24px;
    width: 50%;

    // text-align: center;
  }
  .reason {
    min-height: 300px;
  }
}
</style>
